import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .box {
    cursor: pointer;
    font-size: 0.22rem;
    display: inline-block;
    line-height: 0.55rem;
    text-align: center;
    width: 0.55rem;
    height: 0.55rem;
    color: #636363;
    border: 1px solid #ccc;
    margin-right: 0.4rem;
    margin: 1rem auto;
    margin-right: 0.4rem;

    &.curr {
      color: #fff;
      background: rgb(255, 124, 52);
      border: none;
    }
    &.disable {
      border-color: #ccc;
      color: #ccc;
    }
  }
  &.circle .box {
    border-radius: 50%;
  }
`;
export default function Pagination({
  humanPageNumber = 1,
  pathPrefix = '',
  nextPagePath = '',
  numberOfPages = 1,
  previousPagePath = '',
  isCircle = false
}) {
  const pages = Array.from({ length: numberOfPages }, (v, k) => k + 1) || [];
  return (
    numberOfPages > 0 && (
      <Wrapper className={isCircle && 'circle'}>
        <a
          href={previousPagePath ? `${previousPagePath}` : '#'}
          className={`box ${!previousPagePath && 'disable'}`}
        >
          &lt;
        </a>
        {pages.map((page, index) => {
          return (
            <a
              href={`${pathPrefix}/${page == 1 ? '' : page}`}
              key={index}
              className={`box ${page == humanPageNumber ? 'curr' : ''}`}
            >
              {page}
            </a>
          );
        })}
        <a
          href={nextPagePath ? `${nextPagePath}` : '#'}
          className={`box ${!nextPagePath && 'disable'}`}
        >
          &gt;
        </a>
      </Wrapper>
    )
  );
}
