import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Container from '../../pages/__Container';
import Tabs from '../../pages/__Tabs';

import Banner from '../../pages/__Banner';
import NewsItem from './_NewsItem';
import Pagination from './__Pagination';

import BannerImg from 'imgs/1d1d/pc/banner.news.png';
import _ from 'lodash';

const Wrapper = styled.div`
  background: #fff;
  .sections {
    margin: 0 auto;
    padding: 0.36rem 0.46rem;
    padding-bottom: 0;
    position: relative;
    .newItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      border-bottom: 1px solid #ebebeb;
    }
  }
  .page {
    display: flex;
    justify-content: center;
  }
`;
export default function News({ data, pageContext, ...rest }) {
  const Data = data.allMongodbSsdmDdarticles.edges.map((item) => item.node);

  console.log('items', data, pageContext, rest);
  const { pageType = 1, detailPrefix } = pageContext;
  const chunkItems = _.chunk(Data, 2);
  return (
    <Container>
      <Banner banner={BannerImg} h1="新闻中心" h2s={['news center']} />

      <Wrapper>
        <div className="sections">
          <Tabs selected={pageType - 1} />
          {chunkItems.map((item, index) => (
            <div key={index} className="newItem">
              {item.map((s) => {
                return <NewsItem {...s} detailPrefix={detailPrefix} key={s.title} />;
              })}
            </div>
          ))}
        </div>
        <div className="page">
          <Pagination isCircle={true} {...pageContext} />
        </div>
      </Wrapper>
    </Container>
  );
}
export const query = graphql`
  query($skip: Int!, $limit: Int!, $pageType: Int!) {
    allMongodbSsdmDdarticles(
      skip: $skip
      limit: $limit
      filter: { type: { eq: $pageType } }
      sort: { fields: [isTop, date], order: [DESC, DESC] }
    ) {
      edges {
        node {
          id
          isTop
          date(formatString: "YYYY-MM-DD hh:mm:ss")
          description
          thumbnail
          title
          link
        }
      }
    }
  }
`;
