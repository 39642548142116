import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:nth-child(even) {
    align-items: flex-end;
  }
  .pic {
    width: 2.87rem;
    height: 1.64rem;
    border-radius: 5px;
    overflow: hidden;
    margin: 0.26rem 0 0;
    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .txt {
    width: 2.86rem;
    margin: 0.17rem 0 0.34rem;
    > h2 {
      color: #636363;
      font-size: 0.22rem;
      line-height: 0.3rem;
      height: 0.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;
export default function Banner({ id, title, link, thumbnail, isTop, detailPrefix }) {
  // 置顶加个class ，目测以后会用到的
  return (
    <Wrapper
      className={`${isTop ? 'top' : ''}`}
      href={link ? link : `${detailPrefix}/${id}/`}
      target="_blank"
    >
      <div className="pic">
        <img className="img" src={thumbnail} alt="Banner" />
      </div>
      <div className="txt">
        <h2>{title}</h2>
      </div>
    </Wrapper>
  );
}
